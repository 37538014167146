import SlackIcon from 'assets/img/icons/SlackIcon';
import GPTIcon from 'assets/img/icons/GPTIcon';
import XIcon from 'assets/img/icons/XIcon';
import { Box, IconButton, Tooltip } from '@mui/material';

export interface MenuFooterProps {
  isMobileScreen: boolean;
}

const MenuFooter = ({ isMobileScreen }: MenuFooterProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'column',
        marginBottom: isMobileScreen ? '20px' : '40px',
        gap: '30px',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: isMobileScreen ? `1px solid #F1F1F2` : 0,
        paddingTop: '16px',
      }}
    >
      <Tooltip title="Try our custom GPT in ChatGPT" arrow>
        <IconButton
          onClick={() => {
            window.open('https://gpt.factiverse.ai/', '_blank');
          }}
          sx={{
            zIndex: 2200,
          }}
          size="large"
        >
          <GPTIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Follow us on X" arrow>
        <IconButton
          onClick={() => {
            window.open('https://x.com/factiverseas', '_blank');
          }}
          sx={{
            zIndex: 2200,
          }}
        >
          <XIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Join our Slack community" arrow>
        <IconButton
          onClick={() => {
            window.open(
              'https://join.slack.com/t/factiversecommunity/shared_invite/zt-2vs5apui8-s2k_vrxMRIs79hqxucYOzQ',
              '_blank'
            );
          }}
          sx={{
            zIndex: 2200,
          }}
        >
          <SlackIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default MenuFooter;
