import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const CloseIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '16', height: props.height ?? '17' }}
    >
      <path
        d="M1 1.29492L15 15.2949M15 1.29492L1 15.2949"
        stroke={props.stroke ?? DARK_GREY}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CloseIcon;
