import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const MixedIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 10 2"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '10', height: props.height ?? '2' }}
    >
      <path
        d="M0.178711 0.943359H9.17871"
        strokeWidth="1.5"
        stroke={props.stroke ?? DARK_GREY}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default MixedIcon;
