/* eslint-disable react-hooks/rules-of-hooks */
import { useTheme } from '@mui/material/styles';

export const calculateMaxChars = (lines: number) => {
  const theme = useTheme();
  const screenWidth = window.innerWidth;
  const seeMoreButtonCharCount = 10;

  let contentWidth;

  if (screenWidth <= theme.breakpoints.values.tablet) {
    // Mobile: full width minus margins/padding
    contentWidth = screenWidth - 88;
  } else if (screenWidth <= theme.breakpoints.values.desktop) {
    // Tablet: full width minus margins/padding
    contentWidth = screenWidth - 96;
  } else {
    // Desktop: fixed width accounting for the fixed total content width 1000px
    contentWidth = 720;
  }

  // Font size and average character width calculation
  const fontSize = 18;
  // Approx average width of a character
  const avgCharWidth = fontSize * 0.6;

  // Calculating max characters: (content width / avg char width) * number of lines - approximate charater count for see more button
  const maxChars = Math.floor(
    (contentWidth / avgCharWidth) * lines - seeMoreButtonCharCount
  );

  return maxChars;
};
