import { Typography } from '@mui/material';
import { GREY } from 'utils/theme';

const Title = ({ children }: { children: string }) => {
  return (
    <Typography
      sx={{ color: GREY, pr: 1, textTransform: 'none' }}
      variant="subtitle1"
    >
      {children}
    </Typography>
  );
};

export default Title;
