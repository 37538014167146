import React from 'react';
import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setSearchValue } from 'core/store/claimsV2Slice';
import SuggestedButton from './components/SuggestedButton';
import { useCheckClaim } from '../hooks/useCheckClaim';
import { suggestedClaims } from 'data/suggestedClaims';
import { shuffleArray } from '../helpers';

const SuggestedClaimButtons: React.FC = () => {
  const checkClaim = useCheckClaim();
  const dispatch = useDispatch();

  const handleClaimClick = (claim: string) => {
    dispatch(setSearchValue(claim));
    checkClaim(claim);
  };

  const randomizedClaims = shuffleArray(suggestedClaims);

  const MAX_CLAIMS = 4;
  const MAX_LENGTH = 50;
  let hasLongClaim = false;

  const filteredClaims = randomizedClaims
    .filter((claim) => {
      const isLongClaim = claim.length > MAX_LENGTH;

      if (isLongClaim && hasLongClaim) {
        return false;
      }

      if (isLongClaim) {
        hasLongClaim = true;
      }

      return true;
    })
    .slice(0, MAX_CLAIMS);

  return (
    <Stack
      mt={5}
      direction="row"
      flexWrap="wrap"
      useFlexGap
      spacing={{ xs: 3 }}
      justifyContent="flex-start"
    >
      {filteredClaims.map((claim, index) => (
        <SuggestedButton
          key={index}
          claim={claim}
          onClick={() => handleClaimClick(claim)}
        />
      ))}
    </Stack>
  );
};

export default SuggestedClaimButtons;
