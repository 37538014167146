import { Grid, Stack, Typography } from '@mui/material';
import DisputedIcon from 'assets/img/icons/DisputedIcon';
import MixedIcon from 'assets/img/icons/MixedIcon';
import SupportedIcon from 'assets/img/icons/SupportedIcon';
import { SourceTypes } from 'pages/PlaygroundV2Page/helpers';
import React from 'react';
import { BG_COLOR, DARKER_GREY, DISPUTED, MIXED, SUPPORTED } from 'utils/theme';

interface SourceLabelProps {
  label: SourceTypes | string;
}

const labelConfig = {
  [SourceTypes.SUPPORTING]: {
    color: SUPPORTED,
    icon: () => <SupportedIcon data-testid="SupportedIcon" />,
  },
  [SourceTypes.DISPUTING]: {
    color: DISPUTED,
    icon: () => <DisputedIcon data-testid="DisputedIcon" />,
  },
  [SourceTypes.MIXED]: {
    color: MIXED,
    icon: () => <MixedIcon data-testid="MixedIcon" />,
  },
};

const SourceLabel: React.FC<SourceLabelProps> = ({ label }) => {
  const { color, icon } = labelConfig[label] || {
    color: BG_COLOR,
    icon: () => null,
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: color,
        height: '28px',
        width: '120px',
        borderRadius: '4px',
        padding: '0 8px',
        marginRight: '8px',
      }}
    >
      <Grid item>
        <Stack direction="row" alignItems="center" spacing={1}>
          {icon()}
          <Typography
            color={DARKER_GREY}
            fontSize="13px"
            textTransform="capitalize"
          >
            {label}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SourceLabel;
