import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import MenuLogo from 'assets/img/menuLogo.png';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import YellowButton from 'components/buttons/YellowButton';

import { MAIN_BOX_SHADOW } from 'utils/theme';
import ManageSubscription from './components/ManageSubscription';
import UserMenu from './components/UserMenu';
import MenuIcon from 'assets/img/icons/MenuIcon';
import React, { useState, useEffect } from 'react';
import Sidebar from './components/MenuContent';

const NavBar = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarExpanded((prev) => !prev);
  };

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const toggleUserMenu = () => {
    setIsUserMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isSidebarExpanded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSidebarExpanded]);

  return (
    <Box
      bgcolor="white"
      display="flex"
      flexDirection={isSmallScreen ? 'row' : 'column'}
      alignItems="center"
      justifyContent={isSmallScreen ? 'space-between' : 'flex-start'}
      boxShadow={MAIN_BOX_SHADOW}
      zIndex={1000}
      position={!isSmallScreen ? 'fixed' : 'unset'}
      width={isSmallScreen ? '85%' : 'inherit'}
      height={isSmallScreen ? '56px' : '100vh'}
      borderRadius={isSmallScreen ? '50px' : '0'}
      padding={isSmallScreen ? '0 35px' : 0}
      margin={isSmallScreen ? '24px' : 0}
    >
      <Box
        marginBottom={!isSmallScreen ? '20px' : 0}
        marginTop={!isSmallScreen ? '40px' : '4px'}
        flex={isSmallScreen ? '0 0 auto' : undefined}
      >
        <img
          src={MenuLogo}
          alt="navbarLogo"
          width={isSmallScreen ? '40px' : '50px'}
          style={{
            borderRadius: '8px',
          }}
        />
      </Box>

      {isAuthenticated && (
        <Box
          sx={{
            gap: '16px',
            marginLeft: isSmallScreen ? 'auto' : undefined,
            marginTop: !isSmallScreen ? '20px' : 0,
          }}
        >
          <Avatar
            src={user?.picture}
            sx={{
              width: { desktop: '50px', tablet: '40px' },
              height: { desktop: '50px', tablet: '40px' },
              marginTop: isSmallScreen ? '24px' : '20px',
              marginRight: isSmallScreen ? '24px' : 0,
              transition: '0.3s ease',
              '&:hover': {
                boxShadow: '0 0 0 3px #6C727A30',
                cursor: 'pointer',
              },
            }}
            onClick={toggleUserMenu}
          />
          {<UserMenu isOpen={isUserMenuOpen} setIsOpen={setIsUserMenuOpen} />}
        </Box>
      )}
      {!isSmallScreen && (
        <>
          {isAuthenticated ? (
            <Stack
              spacing={2}
              marginTop={!isSmallScreen ? 'auto' : 0}
              marginBottom={!isSmallScreen ? '80px' : 0}
              alignItems="center"
              direction={isSmallScreen ? 'row' : 'column'}
            >
              <ManageSubscription />
            </Stack>
          ) : (
            <Stack
              spacing={2}
              marginTop={!isSmallScreen ? 'auto' : '24px'}
              marginBottom={!isSmallScreen ? '40px' : '24px'}
              direction={isSmallScreen ? 'row' : 'column'}
            >
              <YellowBorderButton onClick={() => loginWithRedirect()}>
                Sign Up
              </YellowBorderButton>
              <YellowButton onClick={() => loginWithRedirect()}>
                Log In
              </YellowButton>
            </Stack>
          )}
        </>
      )}

      {isSmallScreen && (
        <Box>
          <Button
            onClick={toggleSidebar}
            sx={{
              padding: 0,
              minWidth: 'unset',
              width: '40px',
              height: '40px',
            }}
          >
            <MenuIcon stroke="#545454" />
          </Button>

          {isSidebarExpanded && <Sidebar toggleMenu={toggleSidebar} />}
        </Box>
      )}
    </Box>
  );
};

export default NavBar;
