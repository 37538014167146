import { useAppSelector } from 'core/store/hooks';
import { useEvidenceLabel } from 'pages/PlaygroundV2Page/hooks/useEvidendeLabel';
import useSourceTypes from 'pages/PlaygroundV2Page/hooks/useSourceTypes';
import { convertToCSV, downloadCSV } from '../helpers';

export const useExportActions = () => {
  const evidence = useAppSelector((state) => state.claimsV2.claim.evidence);
  const claim = useAppSelector((state) => state.claimsV2.claim);
  const summary = useAppSelector((state) => state.claimsV2.claim.summary);

  const { getLabel } = useEvidenceLabel();

  const { supportingSources, mixedSources, disputingSources } =
    useSourceTypes(evidence);

  const handleCSVExport = () => {
    const exportToCSVData = evidence
      ? evidence.map((item, index) => ({
          Claim: index === 0 ? claim.claim : '',
          Supporting: index === 0 ? supportingSources?.length : 0,
          Mixed: index === 0 ? mixedSources?.length : 0,
          Disputing: index === 0 ? disputingSources?.length : 0,
          Summary: summary?.[index] || '',
          Domain: item?.domain || '',
          Link: item?.url || '',
          Label: getLabel(item) || '',
          Snippet: item?.snippet || '',
        }))
      : [];

    const csv = convertToCSV(exportToCSVData);
    downloadCSV(csv, 'summary-analysis.csv');
  };

  return { handleCSVExport };
};
