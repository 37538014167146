const getCSVDelimiter = () => {
  const isMac = navigator.userAgent.includes('Macintosh');
  return isMac ? ';' : ',';
};

export const convertToCSV = (data: object[]) => {
  if (data.length === 0) return '';
  const delimiter = getCSVDelimiter();
  const headers = Object.keys(data[0]);

  const escapeField = (field: string) => {
    if (
      typeof field === 'string' &&
      (field.includes(delimiter) || field.includes('"'))
    ) {
      return `"${field.replace(/"/g, '""')}"`;
    }
    return field;
  };

  const csvString = [
    headers.map(escapeField).join(delimiter),
    ...data.map((item) =>
      headers.map((header) => escapeField(item[header] || '')).join(delimiter)
    ),
  ].join('\n');

  return csvString;
};

export const downloadCSV = (csv: string, filename: string) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
