import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import Claim from './Claim';
import Header from './Header';
import useScrollToClaims from './hooks/useScrollToClaims';
import NavBar from './NavBar';
import NoSourcesFoundComponent from './NoSourcesFoundComponent';
import SearchField from './SearchField';
import SuggestedClaimButtons from './SuggestedClaimButtons';
import Summary from './Summary';

const navbarWidth = 145;
const bodyWidth = 1000;

const PlaygroundV2Page = () => {
  const claim = useAppSelector((state) => state.claimsV2.claim);
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const claimHasEvidence = claim.evidence && claim.evidence?.length > 0;
  const isNoDataFound = useAppSelector((state) => state.claimsV2.isNoDataFound);
  const { scrollTargetRef } = useScrollToClaims();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: !claimLoading && claimHasEvidence ? 'auto' : '100vh',
        flexDirection: isSmallScreen ? 'column' : 'row',
      }}
    >
      <Box
        sx={{
          width: isSmallScreen ? '100%' : `${navbarWidth}px`,
          top: 0,
          zIndex: 1000,
          display: 'flex',
          justifyContent: isSmallScreen ? 'center' : 'flex-start',
        }}
      >
        <NavBar />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: {
              mobile: '100%',
              tablet: '100%',
              desktop: `${bodyWidth}px`,
            },
            boxSizing: 'border-box',
            padding: { mobile: '16px', tablet: '24px', desktop: '24px' },
          }}
        >
          {!isSmallScreen && <Header />}
          <SearchField />
          {/* show when there is no evidence was found */}
          {!claimLoading && isNoDataFound && <NoSourcesFoundComponent />}
          {/* show when there is no data and not loading */}
          {!claimLoading && !claimHasEvidence && <SuggestedClaimButtons />}
          {/* show when there is data and loading */}
          {(claimLoading || claimHasEvidence) && (
            <Summary isLoading={claimLoading} claim={claim} />
          )}
          {/* show when there is data and not loading */}
          {!claimLoading && claimHasEvidence && (
            <Box ref={scrollTargetRef}>
              <Claim />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PlaygroundV2Page;
