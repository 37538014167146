import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { extractSummaryTextFromClaim } from 'pages/PlaygroundV2Page/helpers';
import CopyToClipboardButton from './CopyToClipboardButton';
import ReadAloudButton from '../../Claim/components/ClaimEvidence/ReadAloudButton';

interface SummaryFooterProps {
  isLoading: boolean;
  claim: Claim;
}

const SummaryFooter: React.FC<SummaryFooterProps> = ({ isLoading, claim }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const summary = claim.summary;

  const textToCopy = extractSummaryTextFromClaim(claim);
  const summaryText = Array.isArray(summary) ? summary.join(' ') : '';

  if (!summary || isLoading || summary.length === 0) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="100%"
      paddingX={isSmallScreen ? 0 : '30px'}
    >
      <ReadAloudButton text={summaryText} />
      <CopyToClipboardButton textToCopy={textToCopy} />
    </Stack>
  );
};

export default SummaryFooter;
