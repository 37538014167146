import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const MenuOpenIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 8 5"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '8', height: props.height ?? '5' }}
    >
      <path
        d="M1.66372 3.55772L4.00879 1.21265L6.35386 3.55772"
        strokeWidth="1.5"
        stroke={props.stroke ?? DARK_GREY}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default MenuOpenIcon;
