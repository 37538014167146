import React from 'react';
import { Button } from '@mui/material';
import SearchIcon from 'assets/img/icons/SearchIcon';

interface SuggestedButtonProps {
  claim: string;
  onClick: () => void;
  backgroundColor?: string;
  color?: string;
}

const SuggestedButton: React.FC<SuggestedButtonProps> = ({
  claim,
  onClick,
  backgroundColor = 'white',
  color = '#212427',
}) => {
  return (
    <Button
      sx={{
        background: backgroundColor,
        textTransform: 'none',
        borderRadius: '30px',
        paddingY: '12px',
        paddingX: '24px',
        color: color,
        border: '1px solid #6F6F6F30',
        textAlign: 'left',
        justifyContent: 'flex-start',
        transition: 'background-color 0.3s, border-color 0.3s',
        '&:hover': {
          borderColor: '#6F6F6F60',
          backgroundColor: backgroundColor,
        },
      }}
      onClick={onClick}
      endIcon={<SearchIcon sx={{ pl: '30px' }} stroke={'#8289A7'} />}
    >
      {claim}
    </Button>
  );
};

export default SuggestedButton;
