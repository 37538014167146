import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import useUserMetaData from 'hooks/useUserMetaData';
import { useNavigate } from 'react-router-dom';
import DiamondButton from './DiamondButton';

interface ManageSubscriptionProps {
  buttonTitle?: string;
  sx?: object;
}
const ManageSubscription = ({ sx, buttonTitle }: ManageSubscriptionProps) => {
  const { user } = useAuth0();
  const { noSubscription, trialSubscription, proSubscription } =
    useUserMetaData();
  const navigate = useNavigate();

  return (
    <Box>
      {noSubscription || trialSubscription ? (
        <DiamondButton
          buttonTitle={buttonTitle ?? 'Get Pro'}
          onClick={() => {
            navigate('/pricing');
          }}
          sx={{ ...sx }}
        />
      ) : (
        <DiamondButton
          buttonTitle={proSubscription ? 'Pro' : 'Lite'}
          onClick={() => {
            window.open(
              'https://payment.factiverse.no/p/login/8wM6skdVk4bza9G144?prefilled_email=' +
                user?.email,
              '_blank'
            );
          }}
          sx={{ ...sx }}
        />
      )}
    </Box>
  );
};

export default ManageSubscription;
