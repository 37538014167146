import { Stack } from '@mui/material';
import Export from './Export';

const ActionBox = () => {
  return (
    <Stack direction="row" justifyContent="flex-end" spacing={3}>
      <Export />
    </Stack>
  );
};

export default ActionBox;
