import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const DisputedIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 11 11"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '11', height: props.height ?? '11' }}
    >
      <path
        d="M8.58545 3.06445L2.96045 8.68945"
        stroke={props.stroke ?? DARK_GREY}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.58545 8.68945L2.96045 3.06445"
        stroke={props.stroke ?? DARK_GREY}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default DisputedIcon;
