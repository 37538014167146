import {
  Box,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import '../font.css';

import YellowBorderButton from 'components/buttons/YellowBorderButton';
import YellowButton from 'components/buttons/YellowButton';
import React from 'react';
import ManageSubscription from './ManageSubscription';
import MenuFooter from './MenuFooter';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from 'assets/img/Logo.png';
import CloseIcon from 'assets/img/icons/CloseIcon';

interface SidebarProps {
  toggleMenu: () => void;
}

const Sidebar = ({ toggleMenu }: SidebarProps) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        width: isMobileScreen ? '100%' : '300px',
        height: '100%',
        bgcolor: 'white',
        zIndex: 2100,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          margin: isMobileScreen ? '8px 16px 0px 0px' : '24px 24px 8px 0px',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          direction: 'column',
        }}
      >
        <Grid item>
          <Tooltip title="Visit factiverse.ai" arrow>
            <Link
              href="https://factiverse.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Logo}
                alt="Factiverse Logo"
                width={'210'}
                height={'65'}
              />
            </Link>
          </Tooltip>
        </Grid>
        <Box>
          <IconButton
            onClick={toggleMenu}
            sx={{
              zIndex: 2200,
            }}
          >
            <CloseIcon data-testid="CloseIcon" />
          </IconButton>
        </Box>
      </Box>
      {isMobileScreen && (
        <Box p={2} mt="86px">
          <Typography
            fontSize="40px"
            fontWeight="300"
            sx={{
              fontFamily: 'ABCArizonaSerif',
              margin: '16px',
              width: '295px',
            }}
            lineHeight="52px"
          >
            Power your content with credible sources
          </Typography>
        </Box>
      )}

      {isAuthenticated ? (
        <Stack
          spacing={2}
          alignItems="center"
          sx={{
            marginTop: 'auto',
            marginBottom: '24px',
            width: '100%',
          }}
          direction="column"
        >
          <ManageSubscription
            buttonTitle="Get Premium"
            sx={{
              width: isMobileScreen ? '90vw' : '239px',
            }}
          />
        </Stack>
      ) : (
        <Stack
          spacing="12px"
          sx={{
            marginTop: 'auto',
            padding: isMobileScreen ? '30px 16px' : '30px 24px',
          }}
          direction={isMobileScreen ? 'column' : 'row'}
          alignItems="center"
          justifyContent={isMobileScreen ? 'center' : 'space-between'}
        >
          <YellowButton
            sx={{
              width: isMobileScreen ? '100%' : '50%',
              height: isMobileScreen ? '56px' : '44px',
              borderRadius: '200px',
            }}
            onClick={() => loginWithRedirect()}
          >
            Sign Up
          </YellowButton>
          <YellowBorderButton
            sx={{
              width: isMobileScreen ? '100%' : '50%',
              height: isMobileScreen ? '56px' : '44px',
              borderRadius: '200px',
            }}
            onClick={() => loginWithRedirect()}
          >
            Login
          </YellowBorderButton>
        </Stack>
      )}
      {isAuthenticated && <MenuFooter isMobileScreen={isMobileScreen} />}
    </Box>
  );
};

export default Sidebar;
