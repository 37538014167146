import Card from 'pages/PlaygroundV2Page/components/Card';
import {
  ClaimEvidenceBody,
  ClaimEvidenceFooter,
  ClaimEvidenceHeader,
} from './ClaimEvidenceComponents';

const ClaimEvidence = ({
  evidence,
  index,
}: {
  evidence: Source;
  index: number;
}) => {
  return (
    <Card
      sx={{
        padding: {
          mobile: '20px 16px 16px',
          tablet: '20px 16px 16px',
          desktop: '25px 30px',
        },
      }}
      spacing={2}
    >
      <ClaimEvidenceHeader index={index} evidence={evidence} />
      <ClaimEvidenceBody evidence={evidence} />
      <ClaimEvidenceFooter evidence={evidence} />
    </Card>
  );
};

export default ClaimEvidence;
