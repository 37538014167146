import { IconButton, Typography } from '@mui/material';
import DiamondIcon from 'assets/img/icons/DiamondIcon';
import { VANILLA_CREAM, YELLOW, DARKER_GREY } from 'utils/theme';

interface DiamondButtonProps {
  onClick: () => void;
  buttonTitle?: string;
  sx?: object;
}

const DiamondButton = ({ onClick, buttonTitle, sx }: DiamondButtonProps) => {
  return (
    <IconButton
      sx={{
        background: VANILLA_CREAM,
        border: `1.5px solid ${YELLOW}`,
        width: buttonTitle ? 'inherit' : '61px',
        borderRadius: '32px',
        ...sx,
      }}
      onClick={onClick}
    >
      <DiamondIcon sx={{ fontSize: '1.5rem' }} />
      {buttonTitle && (
        <Typography marginLeft="6px" color={DARKER_GREY}>
          {buttonTitle}
        </Typography>
      )}
    </IconButton>
  );
};

export default DiamondButton;
