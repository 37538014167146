import { Button } from '@mui/material';
import MenuCloseIcon from 'assets/img/icons/MenuCloseIcon';
import MenuOpenIcon from 'assets/img/icons/MenuOpenIcon';
import { MouseEvent, forwardRef } from 'react';
import { CLOUD_MIST } from 'utils/theme';

interface MenuOpenButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  isOpen: boolean;
}

const MenuOpenButton = forwardRef<HTMLButtonElement, MenuOpenButtonProps>(
  ({ onClick, isOpen }, ref) => {
    return (
      <Button
        ref={ref}
        sx={{
          background: CLOUD_MIST,
          height: '17px',
          width: '17px',
          borderRadius: '28px',
          minWidth: '18px',
          position: 'relative',
          left: { desktop: '30px', tablet: '20px', mobile: '20px' },
          bottom: '20px',
          '&:hover': {
            background: CLOUD_MIST,
          },
        }}
        onClick={onClick}
      >
        {isOpen ? <MenuCloseIcon /> : <MenuOpenIcon />}
      </Button>
    );
  }
);

MenuOpenButton.displayName = 'MenuOpenButton';

export default MenuOpenButton;
