import {
  Button,
  Divider,
  IconButton,
  Menu as MenuComponent,
  MenuItem,
  Stack,
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import { CLOUD_MIST, LIGHT_BLACK, LIGHT_VIOLET } from 'utils/theme';
import MenuHeader from '../MenuHeader';
import Title from '../Title';

const Styles = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.22))',
    mt: 2,
    width: '177px',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 153,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

interface ActionMenuListItem {
  icon: JSX.Element;
  name: string;
  onClick: () => void;
}

interface MenuProps {
  icon: JSX.Element;
  title: string;
  items: ActionMenuListItem[];
}

const ActionMenuList = ({ icon, title, items }: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Title>{title}</Title>
        <IconButton
          aria-label={title}
          onClick={handleClick}
          sx={{
            color: LIGHT_VIOLET,
            background: open ? CLOUD_MIST : 'white',
            border: '1px solid #6C727A30',
            '&:hover': {
              background: CLOUD_MIST,
            },
          }}
        >
          {icon}
        </IconButton>
      </Stack>
      <MenuComponent
        anchorEl={anchorEl}
        id="user-menu"
        open={open}
        slotProps={{
          paper: Styles,
        }}
        sx={{
          '& .MuiMenu-list': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuHeader title={title} onClick={handleClose} />
        <Divider />
        {items.map((item, i) => {
          return (
            <MenuItem key={i}>
              <Button
                data-testid="action-menu-button"
                startIcon={item.icon}
                sx={{ color: LIGHT_BLACK, '&:hover': { background: 'none' } }}
                onClick={() => {
                  if (item.onClick) item.onClick();
                  setAnchorEl(null);
                }}
              >
                {item.name}
              </Button>
            </MenuItem>
          );
        })}
      </MenuComponent>
    </Stack>
  );
};

export default ActionMenuList;
