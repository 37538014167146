import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const SupportedIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '15', height: props.height ?? '15' }}
    >
      <path
        d="M12.3008 4.32373L6.06641 10.5578L2.94922 7.44092"
        strokeWidth="1.5"
        stroke={props.stroke ?? DARK_GREY}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default SupportedIcon;
