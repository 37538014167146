import { IconButton, Stack } from '@mui/material';
import CloseIcon from 'assets/img/icons/CloseIcon';
import Title from './Title';

interface MenuHeaderProps {
  title: string;
  onClick: () => void;
}

const MenuHeader = ({ title, onClick }: MenuHeaderProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      px={2.25}
      py={1.5}
      justifyContent="space-between"
    >
      <Title>{title}</Title>
      <IconButton onClick={onClick}>
        <CloseIcon sx={{ width: '15px', height: '15px' }} />
      </IconButton>
    </Stack>
  );
};

export default MenuHeader;
