import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { DARKER_GREY } from 'utils/theme';
import Card from '../components/Card';
import { SourceTypes } from '../helpers';
import SourceLabel from './components/SourceLabel';
import SuggestedSearches from './components/SuggestedSearches';

interface NoSourcesFoundComponentProps {
  suggestedSearches?: string[];
}

const messages = {
  headerText: 'Not enough predictions',
  descriptionText:
    'Factiverse AI editor needs at least 5 supporting or disputing sources to predict and display results. Try rephrasing the claim.',
};

const NoSourcesFoundComponent: React.FC<NoSourcesFoundComponentProps> = ({
  suggestedSearches = [],
}) => {
  const isSuggestedSearchDataAvailable =
    suggestedSearches && suggestedSearches.length > 0;
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const Header: React.FC = () => (
    <Stack direction="row" alignItems="center" width="100%">
      <Typography variant="h6">{messages.headerText}</Typography>
    </Stack>
  );

  const LabelGroup: React.FC = () => (
    <Stack direction="row" alignItems="center" mt={3} spacing={1}>
      <SourceLabel label={SourceTypes.SUPPORTING} />
      <SourceLabel label={SourceTypes.DISPUTING} />
      <SourceLabel label={SourceTypes.MIXED} />
    </Stack>
  );

  const Description: React.FC = () => (
    <Stack direction="row" alignItems="center" mt={3} width="100%">
      <Typography color={DARKER_GREY} fontSize="18px">
        {messages.descriptionText}
      </Typography>
    </Stack>
  );

  return (
    <Card
      paddingX={isMobileScreen ? '16px' : '60px'}
      paddingY={isMobileScreen ? '16px' : '40px'}
    >
      <Grid container>
        <Grid
          item
          xs={isMobileScreen ? 12 : 6}
          sx={{
            borderRight: isSuggestedSearchDataAvailable
              ? '1px solid lightgrey'
              : 0,
          }}
        >
          <Header />
          <LabelGroup />
          <Description />
        </Grid>

        <Grid
          item
          xs={6}
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {isSuggestedSearchDataAvailable && (
            <SuggestedSearches suggestedSearchesData={suggestedSearches} />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default NoSourcesFoundComponent;
