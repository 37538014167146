import { Stack } from '@mui/material';
import ExpandLessIcon from 'assets/img/icons/ExpandLessIcon';
import ExpandMoreIcon from 'assets/img/icons/ExpandMoreIcon';
import { setSelectedSortingAccordion } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { SourceTypes } from 'pages/PlaygroundV2Page/helpers';
import useSourceTypes from 'pages/PlaygroundV2Page/hooks/useSourceTypes';
import { useDispatch } from 'react-redux';
import {
  BRIGHT_BLUE,
  CLOUD_MIST,
  DARK_GREY,
  LIGHT_GREEN,
  LIGHT_PINK,
  LIGHT_YELLOW,
} from 'utils/theme';
import AccordionItem from './AccordionItem';

const SortingAccordion = ({ evidence }: { evidence: Source[] }) => {
  const dispatch = useDispatch();
  const { groupedSourceTypes } = useSourceTypes(evidence);
  const selectedSortingAccordion = useAppSelector(
    (state) => state.claimsV2.selectedSortingAccordion
  );

  const accordionItems = [
    {
      name: SourceTypes.ALL,
      itemColor: CLOUD_MIST,
    },
    {
      name: SourceTypes.SUPPORTING,
      itemColor: LIGHT_GREEN,
    },
    {
      name: SourceTypes.MIXED,
      itemColor: LIGHT_YELLOW,
    },
    {
      name: SourceTypes.DISPUTING,
      itemColor: LIGHT_PINK,
    },
  ];

  return (
    <Stack direction="row">
      {accordionItems.map(({ name, itemColor }, key) => {
        const items = groupedSourceTypes[name];

        if (!items || items.length === 0) {
          return null;
        }
        return (
          <AccordionItem
            key={key}
            title={name}
            count={items?.length}
            itemColor={itemColor}
            onClick={() => {
              dispatch(setSelectedSortingAccordion(name));
            }}
            titleColor={
              selectedSortingAccordion === name ? BRIGHT_BLUE : DARK_GREY
            }
            endIcon={
              selectedSortingAccordion === name ? (
                <ExpandLessIcon stroke={BRIGHT_BLUE} />
              ) : (
                <ExpandMoreIcon />
              )
            }
            selected={selectedSortingAccordion === name}
          />
        );
      })}
    </Stack>
  );
};

export default SortingAccordion;
