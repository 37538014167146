import { useMemo } from 'react';
import { SourceTypes } from '../helpers';

export const useSourceTypes = (evidence: Source[] | undefined) => {
  const supportingSources = evidence?.filter((item) => {
    return item.softmaxScore[1] >= 0.6;
  });

  const mixedSources = evidence?.filter((item) => {
    return item.softmaxScore[1] > 0.4 && item.softmaxScore[1] < 0.6;
  });

  const disputingSources = evidence?.filter((item) => {
    return item.softmaxScore[1] <= 0.4;
  });

  type SourceTypeGroups = Record<SourceTypes, Source[]>;

  const groupedSourceTypes = useMemo(() => {
    if (!evidence) return [];
    return evidence.reduce<SourceTypeGroups>(
      (groups, item) => {
        const score = item.softmaxScore[1];

        groups[SourceTypes.ALL].push(item);

        if (score >= 0.6) {
          groups.supporting.push(item);
        } else if (score > 0.4 && score < 0.6) {
          groups.mixed.push(item);
        } else {
          groups.disputing.push(item);
        }
        return groups;
      },
      {
        [SourceTypes.SUPPORTING]: [],
        [SourceTypes.MIXED]: [],
        [SourceTypes.DISPUTING]: [],
        [SourceTypes.ALL]: [],
      }
    );
  }, [evidence]);

  return {
    supportingSources,
    mixedSources,
    disputingSources,
    groupedSourceTypes,
  };
};

export default useSourceTypes;
