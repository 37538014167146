import { IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from 'assets/img/icons/CloseIcon';
import { setOpenFilterPopover } from 'core/store/claimsV2Slice';
import { useDispatch } from 'react-redux';

const PopoverHeader = () => {
  const dispatch = useDispatch();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      px={4}
      py={2}
    >
      <Typography fontSize="1.15rem" fontWeight="medium">
        Filter
      </Typography>
      <IconButton
        aria-label="closeIcon"
        onClick={() => dispatch(setOpenFilterPopover(false))}
        sx={{ p: '4px', width: '30px', height: '30px' }}
      >
        <CloseIcon
          sx={{
            stroke: '#6F6F6F',
            width: '14px',
            height: '14px',
            strokeWidth: '1.5',
          }}
        />
      </IconButton>
    </Stack>
  );
};

export default PopoverHeader;
