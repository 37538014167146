import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import Sum from 'components/Sum';
import { ReactNode } from 'react';
import { ISMOBILE_BREAKPOINT } from 'utils/theme';

interface AccordionItemProps {
  title: string;
  itemColor: string;
  count: number;
  endIcon: ReactNode;
  onClick: () => void;
  titleColor: string;
  selected: boolean;
}

const AccordionItem = ({
  title,
  itemColor,
  count,
  onClick,
  endIcon,
  titleColor,
  selected,
}: AccordionItemProps) => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);

  return (
    <Button
      onClick={onClick}
      endIcon={endIcon}
      sx={{
        borderBottom: selected ? '2px solid #4D91FF' : undefined,
        borderRadius: '0px',
        paddingX: '15px',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white',
        },
      }}
    >
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={0.5}
        alignItems="center"
      >
        <Sum color={itemColor} count={count} isSum={true} />
        <Typography fontSize="14px" color={titleColor}>
          {title}
        </Typography>
      </Stack>
    </Button>
  );
};

export default AccordionItem;
