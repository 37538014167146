// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/fonts/ABCArizonaSerif-Light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face { \n    font-family: 'ABCArizonaSerif';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\n    font-weight: 300; \n    font-style: normal; \n  }", "",{"version":3,"sources":["webpack://./src/pages/PlaygroundV2Page/NavBar/font.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,+DAA0E;IAC1E,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":["@font-face { \n    font-family: 'ABCArizonaSerif';\n    src: url('/src/assets/fonts/ABCArizonaSerif-Light.otf') format('opentype');\n    font-weight: 300; \n    font-style: normal; \n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
