import {
  Box,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import SupportedIcon from 'assets/img/icons/SupportedIcon';

interface SummaryTextProps {
  isLoading: boolean;
  summary: string[];
}

const SummaryText: React.FC<SummaryTextProps> = ({ isLoading, summary }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      {isLoading ? (
        <Box>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </Box>
      ) : (
        <Box>
          {summary && summary.length !== 0 ? (
            summary.map((sum, key) => (
              <Stack
                alignItems="flex-start"
                direction="row"
                mb={2}
                mt={2}
                key={key}
              >
                {!isMobileScreen && (
                  <Box paddingX="6px" pt="6px">
                    <SupportedIcon />
                  </Box>
                )}
                <Typography fontSize="18px">{sum}</Typography>
              </Stack>
            ))
          ) : (
            <Stack alignItems="flex-start" direction="row" mb={2} mt={2}>
              {!isSmallScreen && (
                <Box paddingX="6px" pt="6px">
                  <SupportedIcon />
                </Box>
              )}
              <Typography
                sx={{
                  fontSize: '18px',
                }}
              >
                Summary isn’t available for this content at the moment. Please
                review the content down bellow for detailed information.
              </Typography>
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SummaryText;
