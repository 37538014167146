import { Button } from '@mui/material';
import { YELLOW, LIGHT_BLACK } from 'utils/theme';

interface YellowButtonProps {
  children: string;
  onClick: () => void;
  disabled?: boolean;
  sx?: object;
}

const YellowButton = (props: YellowButtonProps) => {
  const { children, onClick, disabled, sx } = props;
  return (
    <Button
      sx={{
        border: disabled ? 'lightgrey' : `1px solid ${YELLOW}`,
        borderRadius: '20px',
        color: LIGHT_BLACK,
        textTransform: 'none',
        fontSize: '0.875rem',
        background: disabled ? 'lightgrey' : YELLOW,
        '&:hover': {
          backgroundColor: 'white',
        },
        paddingX: '1em',
        ...sx,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default YellowButton;
