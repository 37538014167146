import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const LogoutIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 14 12"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '14', height: props.height ?? '12' }}
    >
      <path
        d="M6.33333 11.2766H11C12.1046 11.2766 13 10.3812 13 9.27661V2.60995C13 1.50538 12.1046 0.609944 11 0.609944H6.33333M1 5.94328L8.33333 5.94328M8.33333 5.94328L6.33333 7.94328M8.33333 5.94328L6.33333 3.94328"
        stroke={props.stroke ?? DARK_GREY}
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default LogoutIcon;
