export const suggestedClaims: Array<string> = [
  'The Mafia was involved in the making of "The Godfather"',
  'Indian Cricketer MS Dhoni voted for the Congress party',
  'COVID-19 vaccines caused a 14,000 per cent increase in cancer cases',
  'HIV can be cured using gene therapy',
  'Eating 3 ripe papaya seeds a day can cure cancer',
  'A couple in Brazil planted over 2 million trees in 20 years to recreate the forest',
  'Taylor Swift is doing satanic rituals during her shows',
  'Trump says business records case about hush money is a "Biden trial."',
  "Joe Biden's open border policies are bringing tuberculosis to the US",
  "1950s 'Atomic Energy Lab' Kit for Kids Used Real Radioactive Materials",
  '‘The Simpsons’ predicted police investigation of the rapper known as Diddy',
  "Kate Middleton's Turbo Cancer was caused by her mRNA vaccination",
  'Muslims who want to live under Sharia law should leave, says former Australian PM',
  'Iran attacked the U.S. Naval vessel USS Liberty off the coast of Bahrain in April 2024',
  "McDonald's congratulates Israeli military over Gaza killings",
  'Bill Gates said he wants to replace all farmers with AI',
  'Muslims killed 8 million Buddhists in Indonesia in 1965',
  'Japan has banned COVID-19 vaccines because of soaring deaths',
  'Many Wisconsin police agencies have two officers present during the analysis of drugs',
  'The Great Lakes of North America account for over 20% of the world’s freshwater supply',
  'The Pope Congratulates Putin on His Victory in the Presidential Election',
  'South Korean opposition politicians were kowtowing ahead of the 2024 vote',
  'Five underwater volcanoes near Hinatuan caused an earthquake in the Philippines',
  'The Republic of Kiribati is the only country in the world to touch all four hemispheres',
  'Drinking extract from Moringa leaves can cure HIV',
  'From April 2022 to March 2023, King Charles III made $34 million in rent',
  'New York is giving migrants up to $10,000 on debit cards with no restrictions',
  'A peer-reviewed study proves COVID-19 vaccines are more harmful than beneficial',
  'A man renovating his home in Turkey found a vast ancient underground city',
  'In the UK, a 10-hour shift as a junior doctor pays £140',
  'In New York City "local elections, illegal immigrants can vote."',
  'Women are less suited to work in STEM',
  'Women now own approximately 40% of all businesses in the United States',
  'International Womens Day began in the US as part of a socialist movement',
  'Solar flares caused AT&T cellphone outage',
  'Trump claims credit for overturning Roe v. Wade',
  'As of January 2024, more than 7.2 million migrants had illegally crossed into the U.S',
  'Free medicine and education will be canceled in Ukraine',
  'COVID-19 vaccine recipients might not be eligible for blood donation',
  'The NHS Covid-19 app cost £37 billion',
  'The UK spends 2.5% of GDP on defence',
  'Under President Joe Biden, "Black unemployment is the lowest in American history."',
  'A Chinese spy pidgeon has been caught in India',
  'Drinking water at specific times can have health benefits',
  'Oat milk raises your blood sugar as much as drinking a Coke',
  '"In August 2023, illegal immigration outpaced American births."',
  'Oil regenerates faster within Earth than it can be depleted',
  'Norwegians say "Texas" as slang to mean wild or crazy',
  'Measles is not a threat to people living in the developed world',
  'Most carbon emissions have nothing to do with fossil fuels',
  'Solar farms cause extreme weather changes',
  'Eating too much sour candy can cause the tongue to peel',
  '"Nearly every young person aged 15 to 24 years is literate."',
  '"85% of South African homes have access to electricity."',
  '"89% of households in South Africa now have access to water."',
  'A juice of carrots, cucumbers and garlic will "restore perfect vision in seven days."',
  '"The racial wealth gap in the US is the smallest it’s been in 20 years."',
  'The 1939 flag of Palestine featured the Star of David',
  'Barack Obama deported more people than Donald Trump did',
  'Offshore wind farms will kill whales',
  'A comic first published in 1919 predicted cell phones',
  'Giraffes have the same number of neck bones as humans',
  'It is estimated that there are more trees on Earth than stars in the Milky Way galaxy',
  'No two snowflakes are exactly alike',
  'Amazon is converting delivery fleet to diesel',
  'Historic bison herds had the same impact on the climate as US cattle today',
  'If you have a pacemaker, you can donate it to a dog after you die',
  'The sweetener Acesulfame K contains the carcinogen methylene chloride',
  'The EU wants to SEIZE your old car to meet climate goals',
  'NASA is installing internet on the moon',
  '"In every single war that America has fought, we have never asked for land afterwards."',
  'One day on Venus is longer than a year on Venus',
  'The pyramids are so old that even King Tut might have called them "ancient."',
  'The Earth has the lowest average CO2 levels in the last 600 million years',
  'Blood donations are tainted by Covid-19 vaccines',
  '"Half of American high school students are not familiar with the Holocaust."',
  'One in four cancers are as a result of smoking',
  'London went from 86% white to 36% in ten years',
  'The UK is doubling its aid funding for Palestinian civilians',
  'The BBC cut off its own journalist for reporting that Israel used chemical weapons',
  'Gen Z is divided 50-50 on whether they support Hamas or Israel',
  'Israel bombed Palestinian children trying to get water',
  'The life span of a wind tower generator lasts just three to four years',
  'No hurricane has been known to cross the equator',
  'The majority of toothpastes contain a toxic chemical, fluoride',
  'Forty percent of 10- to 11-year-olds in the UK are obese',
  'Lockdown caused a rise in suicides among elderly people',
  'The number one cause of hospital admissions among children is tooth decay',
  'An Israeli airstrike hit a church in the Gaza Strip',
  'Half a million Israelis protested against President Benjamin Netanyahu on October 14',
  'One in three female surgeons have been assaulted by a colleague in the past five years',
  'The Indian Parliament kicked the Bill & Melinda Gates Foundation out of India',
  '"The U.S. is sending $8 billion worth of military aid to Israel."',
  'As of mid-2023, 50% of all people in Palestine were children',
  'Dietary cholesterol does not affect bodily cholesterol',
  'China sends "fentanyl into America killing 80,000 Americans a year."',
  'Sweden confirmed the loss of all tanks donated to Ukraine',
  'In early October 2023, thousands of Ukrainian soldiers surrendered to Russia',
  'One in five children have used vapes in the UK',
  'Iodised salt is harmful to the body',
  'Drinking beer improves human health',
  'CEOs are now making 400 times more than their average worker',
  'Texas teacher fired for reading Diary of Anne Frank to class',
  "The U.S. 'Accidentally' Sent $6 Billion To Ukraine",
  'In July 2023, Vladimir Putin said that Poland wants to "regain" western Ukraine',
  'Climate-related disaster deaths are in decline',
  'Honeybees were not considered "endangered" in the U.S. as of September 2023',
  'Nearly two thirds of people aged 30-50 could be living with undiagnosed blood pressure',
  'Smart meters allow energy suppliers to turn off your supply remotely',
  'Delays to using a defibrillator puts "heart attack victims" at higher risk of death',
  'America’s C40 cities will ban meat, dairy, new clothes, private cars by 2030',
  'In August 2023, Pope Francis said transgender people are "children of God."',
  'Hippopotamuses are too dense to swim or float',
  'Canada has more lakes than all other countries combined',
  'Gandhi used to receive allowance of Rs. 100 monthly by the British government',
  'Using mobile phones during thunderstorms will attract lightning',
  'You can reduce your risk of developing diabetes by eating earlier meals',
  'Directed energy weapons caused fires in Hawaii',
  '13 nations agree to abolish farming in order to save the planet',
  'A lot of your chain-food restaurants have been using lab-grown meat for years',
  'The James Webb Telescope discovered a "question mark" formation in space',
  'There is an extreme sunburn competition in Florida',
  'Skin cancer is a relatively new phenomenon in the last 60 years or so',
  'More people are killed annually by cows than sharks',
  'Hairdressers have a higher cancer risk because of chemicals at work',
  'At the peak of slavery in 1860, only 1.4% of Americans Owned Slaves',
  'During the "COVID lockdowns," the average American gained 29 pounds',
  'Amish Death Rates 90 Times Lower Than Rest of America',
  "The EU Nature Restoration Law will turn Lapland's capital into a forest",
  'Bibles have been banned in California',
  'Politico reported that 20 million Ukrainians would die to win war',
  'Lab grown meat is grown using cancerous cells',
  'Land use, farming have no meaningful climate impact',
  'Humans share 50% of their DNA with bananas',
  'NASA has issued a warning about a pending "internet apocalypse" caused by a solar storm',
  'You can pay for a bus ticket in Romania by doing 20 squats',
  'Boris Johnson will receive a £115,000-a-year pension for life',
  'A Mickey Mouse gas mask was produced during World War II',
  'Google Maps is adding a feature that allows drivers to challenge each other to a race',
  'You can only get hepatitis B from sharing a needle or having unprotected sex',
  'Raw milk cures lactose intolerance',
  'Hunter Biden charged with failing to pay federal income tax and illegally having a weapon',
  'Ukraine flooded the country on purpose by destroying the Nova Kakhovka dam',
  'Boris Johnson won the largest general election majority for 44 years',
  'Barack Obama said that Iran has a "right" to nuclear technology, including weapons',
  'Norway has banned gender-affirming care for minors',
  'An 11-year-old boy saved the lives of two people on the same day',
  'The U.S. "takes 87% of all the prescription medications in the world."',
  'King Charles inherited an estate worth £22 billion and his coronation cost £250 million',
  'The share of working-age men choosing to work is the lowest it has ever been',
  'Gender Change Results In Higher Risk Of Suicide',
  'Most generic sunscreens on the market contain benzenes which are a cancer-causing agent',
  'All fish in the English Channel belong to the EU',
  '"One Night Cough Syrup" from the late 1800s contained cannabis, morphine, and chloroform',
  'US is giving free smartphones to migrants',
  'More than 24,000 people in India died from 2023 heatwave',
  'Australia Bureau of Meteorology doctored temperature data',
  'Women aged over 55 are the largest group experiencing homelessness',
  'German judges in court cases did not rule on whether measles virus exists',
  'Drinking cold water in hot environment causes stroke',
  'People with diabetes should not eat fish without scales',
  'Solar and wind power drive up electricity prices',
  'Joe Biden was older on his first day as president than Ronald Reagan was on his last day',
  'There is "$500 billion of unspent COVID money" that can be rescinded',
  'In a vegan world there would be no pandemics. 100% of pandemics are zoonotic in origin',
  'Stephen Hawking warned that artificial intelligence could mean the "end of the human race."',
  'Northern Ireland has the highest levels of child poverty in the UK',
  'Florida’s DeSantis ordered National Guard to block Disney entrances',
  'Fentanyl is the leading cause of death for Americans 18-45',
  'A protein in human saliva called Histatin can help wounds heal faster',
  'The Antarctic ice cap is not melting',
  'US to send nuclear ballistic submarines to Korean Peninsula',
  'Oktoberfest in Munich, Germany, will not offer Budweiser or Bud Light products',
  'Florida has "the second lowest tax burden per capita in the United States."',
  'One in five Americans has lost a family member to gun violence',
  'Since President Biden took office, families have lost the equivalent of $7,400 worth of income',
  "New Zealand's Waitangi Tribunal has the power to veto legislation and government decisions",
  'Using saunas reguarly reduces risk of death from any cause by 30 to 40%',
  'Florida Gov. Ron DeSantis got married at Disney World in 2009',
  'Some Parmesan cheeses are made using the stomach linings of baby cows',
  'There are 15 million child workers in Nigeria',
  'Report proves that COVID-19 vaccines caused 300,000 excess deaths in 2022',
  'You can optimize your health by using glucose monitors to prevent blood sugar spikes',
  'The last public execution in the United States was held in the 1930s',
  'Vitamins aren’t FDA approved, but Twinkies are',
  'There is no evidence of increased support for Northern Ireland leaving the UK',
  'Ukraine burns its own and russian wheat fields',
  'Finland is transferring tanks to the border with Russia',
  'No one has ever died of tetanus within minutes or hours',
  'A US government program installs free solar panels',
  'In the summer of 2009, TikTok CEO interned at Facebook',
  'A London Gallery is removing a portrait due to subject’s likeness to Vladimir Putin',
  'Australia is wider than the moon',
  'The US Congress has officially banned TikTok',
  'Bill Gates want to control the population by killing people with vaccines',
  'German cities are adding Arabic to street signs which demonstrates "replacement migration"',
  'An all-female investment team was leading Silicon Valley Bank (SVB) when it collapsed',
  "Gays take up 2% of the world's population, but commit 45% of sexual assaults against children",
  "Biden's expansion of the child tax credit cut Black child poverty in half in 2021",
  'Sri Lankan President bans protests in the country from March 7',
  'Silicon Valley Bank "donated $73M to BLM Movement"',
  "Bill Clinton paid Paula Jones $850,000 in 'hush money'",
  'More cops are killed responding to domestic violence calls than anything else',
  'Trudeau threatens to pull health funding without digital IDs',
  'The Ahu plant is a powerful drug that eradicates cancer',
  'Lithuanian artist designed a "Euthanasia Coaster" intended to kill its passengers',
  'Dinosaurs never existed. They were invented by the Royal Society in 1841',
  'A South African electricity utility recommends using hand signals while driving to save energy',
  '‘The Simpsons’ predicted the collapse of Silicon Valley Bank',
];
