import { BG_COLOR, DISPUTED, MIXED, SUPPORTED } from 'utils/theme';
import { SourceTypes } from '../helpers';

export const useEvidenceLabel = () => {
  const MIXED_MIN = 0.4;
  const MIXED_MAX = 0.6;
  const USE_MIXED = true;

  const getLabel = (source: Source) => {
    const score = source.softmaxScore[1];
    switch (true) {
      case score <= MIXED_MIN:
        return SourceTypes.DISPUTING;
      case USE_MIXED && score > MIXED_MIN && score < MIXED_MAX:
        return SourceTypes.MIXED;
      case score >= MIXED_MAX:
        return SourceTypes.SUPPORTING;
      default:
        return 'Unkown';
    }
  };

  const getColor = (source: Source) => {
    const support = getLabel(source);
    switch (support) {
      case SourceTypes.DISPUTING:
        return DISPUTED;
      case SourceTypes.MIXED:
        return USE_MIXED ? MIXED : BG_COLOR;
      case SourceTypes.SUPPORTING:
        return SUPPORTED;
      default:
        return BG_COLOR;
    }
  };

  return { getLabel, getColor };
};
